<template>
  <div class="spec-wrapper">
    <OverlayLoad :show="overlayShow"></OverlayLoad>
    <van-popup v-model="popupShow" position="bottom" @click-overlay="handleClose" safe-area-inset-bottom closeable
      :style="{ height: '90%' }">
      <div class="spec-content">
        <spec-header :selectedSpec="selectedSpec"></spec-header>
        <div class="spec-content-middle">
          <spec-lump>
            <div class="address-warpper">
              <div class="address-title">
                配送区域<span class="address-title-tip">(配送地会影响库存，请正确选择)</span>
              </div>
              <van-cell :title="addressInfo.address" icon="location-o" is-link @click="handleAddressOpen" />
            </div>
          </spec-lump>
          <spec-lump v-if="productType === '0'">
            <ticket-choose :venue-list="venueList" @change="handleTicketChange">
            </ticket-choose>
          </spec-lump>
          <spec-lump v-else>
            <spec-list-choose :list="specList" @change="handleSpecChange" @loadAfter="handleSpecChange">
            </spec-list-choose>
          </spec-lump>
          <spec-lump>
            <div v-if="!this.noneAddressFlag && !this.noneSupportFlag" class="address-warpper">
              <div class="address-title">配送方式</div>
              <van-cell :title="deliveryInfo.sendTypeStr" icon="logistics" is-link @click="handleOpenSendTypeClick" />
            </div>
          </spec-lump>
          <spec-lump v-if="!this.noneAddressFlag && !this.noneSupportFlag && sendSelectType === 1">
            <div class="address-warpper">
              <div class="address-title">商家地址</div>
              <van-cell :title="shopInfo ? shopInfo.address : ''" icon="shop-collect-o" is-link
                @click="shopAddressShow = true" />
            </div>
          </spec-lump>
          <spec-lump>
            <div class="field-wrapper">
              <div class="field-label">
                购买数量<span v-if="selectedSpec.limitNum" class="title-tip">限购{{ selectedSpec.limitNum }}件</span>
              </div>
              <div class="field-content">
                <van-stepper :disable-minus="payCount <= 1"
                  :disable-plus="selectedSpec.limitNum ? payCount >= selectedSpec.limitNum : false"
                  :disabled="!selectedSpec.id" disable-input @overlimit="handleOverlimit" @change="onChange"
                  v-model="payCount" />
              </div>
            </div>
          </spec-lump>
          <spec-lump v-if="goodsType === 'cake'">
            <div class="address-warpper">
              <div class="address-title">祝福语</div>
              <div class="field-content">
                <van-field v-model="leaveWordText" left-icon="gift-card-o" rows="1" autosize type="textarea"
                  maxlength="20" :placeholder="leaveWordPlaceholder" show-word-limit :disabled="this.noneAddressFlag || this.noneSupportFlag" />
              </div>
            </div>
          </spec-lump>
          <spec-lump v-if="goodsType === 'cake'">
            <div class="address-warpper">
              <div class="address-title">备注</div>
              <div class="field-content">
                <van-field v-model="notesText" left-icon="notes-o" rows="1" autosize type="textarea" maxlength="200"
                  :placeholder="notesPlaceholder" :disabled="this.noneAddressFlag || this.noneSupportFlag" />
              </div>
            </div>
          </spec-lump>
        </div>

        <div class="pay-button-wrapper">
          <van-goods-action>
            <van-goods-action-button v-if="addCartShow && productType !== '0'" type="warning" :disabled="cantBuy"
              :text="textCar" @click="handleAddMyCartClick" />
            <van-goods-action-button v-if="buyNowShow" type="danger" :disabled="cantBuy" :text="textBuy"
              @click="handlebuyNowClick" />
          </van-goods-action>
        </div>
      </div>
    </van-popup>
    <AddressPopup v-if="specDataLoad" ref="addressPopupRef" @change="handleSeleceAddressItem"
      @loadAfter="handleSeleceAddressItem"></AddressPopup>
    <!-- 配送 -->
    <SendType v-if="sendTypeShow" :columns="deliveryRules" @TypeConfirm="handleTypeConfirm"
      @TypeCancel="sendTypeShow = false" @closed="sendTypeShow = false">
    </SendType>
    <ShopAddress v-if="shopAddressShow" :shopList="shopList" @closed="shopAddressShow = false"
      @seleceShopItem="handleSelectShopChange"></ShopAddress>
  </div>
</template>

<script>
import SpecListChoose from "./SpecListChoose.vue";
import TicketChoose from "./TicketChoose.vue";
import SpecLump from "./SpecLump.vue";
import SpecHeader from "./SpecHeader.vue";
import AddressPopup from "./AddressPopup.vue";
import OverlayLoad from "./OverlayLoad.vue";
import {
  getTypeConfirm,
  getSpecInfo,
  isLogin,
  queryDistributionRules,
  getSendSelectType,
  getShopListInfo,
  isNoEmptyArr,
} from "./index.action";
import SelectAddress from "../goods/SelectAddress.vue";
import { Toast } from "vant";
import SendType from "../goods/SendType.vue";
import ShopAddress from "../goods/ShopAddress.vue";
export default {
  components: {
    ShopAddress,
    OverlayLoad,
    SendType,
    SpecHeader,
    SpecListChoose,
    SelectAddress,
    SpecLump,
    AddressPopup,
    TicketChoose
  },
  props: {
    goodsType: {
      type: String
    },
    productType: {
      type: String
    },
    list: {
      type: Array
    },
    /**
     * 城市list
     */
    addressList: {
      type: Array
    },
    // 演出票选择规格数据
    venueList: {
      type: Array
    },
    currentDistribution: {
      // 当前的配送规则
    },
    currentAddress: {
      // 当前选择的地址
      type: Object
    },
    channelType: {
      type: Number, // 1 全品 2 蛋糕 3 电影
      default: 1
    },
    queryForm: {
      // 查询商品信息所需参数
      type: Object,
      default: function () {
        return {
          cardId: "",
          cityCode: "",
          brandId: "",
          productId: ""
        };
      }
    }
  },
  data() {
    return {
      leaveWordText: '', // 留言信息
      notesText: '', // 备注信息
      buyNowShow: true, // 立即购买按钮显示
      addCartShow: true, // 加入购物车按钮显示
      specDataLoad: false, // 规格数据加载锁
      sendTypeShow: false, // 配送方式选择弹窗开启状态
      overlayShow: false,
      popupShow: false, // 是否打开展示板
      shopAddressShow: false, // 打开商铺选择页面
      deliveryRules: [], // 配送规则
      deliveryInfo: {}, //配送信息
      shopInfo: {}, // 店铺信息
      sendSelectType: 0,
      isDistribution: null,
      addressInfo: {
        address: "",
        consigneeAddress: "",
        consigneeName: "",
        consigneePhone: "",
        addressId: ""
      }, // 收货地址信息
      selectedSpec: {}, // 当前选择的规格信息
      specList: [], // 规格信息数组
      cantBuy: true,// 不能买和加入购物车
      textCar: '加入购物车',
      textBuy: '立即购买',
      payCount: 1, // 购买数量
      target: null,
      validate_delivery_dates: [],//商家配送数据，计算运费使用
      showAddressBar: 1,// 是否显示结算页面的上方地址栏. 快递商品可以显示,蛋糕类不支持快递的不显示
      noneAddressFlag: false,// 无地址标识
      noneSupportFlag: false,// 不支持配送标识
      distributionRulesLock: false,// 配送规则获取锁
      leaveWordPlaceholder: '请输入祝福语，最多20个字',// 祝福语占位符
      notesPlaceholder: '请输入需要备注的内容',// 留言占位符
    };
  },
  watch: {
    list: {
      handler: function (newVal) {
        if (isNoEmptyArr(newVal)) {
          this.specList = newVal;
        }
      },
      immediate: true
    }
    // currentAddress: {
    //   handler: function (newVal) {
    //     this.initAddressInfo(newVal, this.addressList);
    //   },
    //   immediate: true
    // }
  },
  methods: {
    /**
     * 配送方式选择回调地址
     */
    async handleTypeConfirm(record) {
      this.sendTypeShow = false;
      // 根据 选择信息获取配送信息
      const deliveryInfo = getTypeConfirm(record);
      this.deliveryInfo = deliveryInfo;
      this.sendSelectType = getSendSelectType(deliveryInfo.sendTypeStr);
      if (this.sendSelectType === 1) {
        // 当前选择的是 门店自提
        const shopList = await getShopListInfo(this.addressInfo.addressId, this.queryForm.brandId);
        this.shopList = shopList;
      } else if (this.sendSelectType === 3) {
        this.validate_delivery_dates.forEach(element => {
          if (element.date === record[1]) {
            this.deliveryInfo.deliveryAmount = element.delivery_amount
            if (element.delivery_amount === '0') {
              this.deliveryInfo.sendTypeStr = this.deliveryInfo.sendTypeStr + ' 免运费'
            } else {
              this.deliveryInfo.sendTypeStr = this.deliveryInfo.sendTypeStr + ' 运费' + this.deliveryInfo.deliveryAmount + '点'
            }
          }
        });
      }
      this.$emit("changeSendType", record);
    },
    /**
     * 规格选择框打开方法
     */
    async open(typeStr) {
      if (typeStr === "addCart") {
        this.buyNowShow = false;
        this.addCartShow = true;
      } else if (typeStr === "buyNow") {
        this.buyNowShow = true;
        this.addCartShow = false;
      } else {
        this.buyNowShow = true;
        this.addCartShow = true;
      }
      // 每次打开规格页面,都处理一遍配送规则.
      if (this.selectedSpec.id) {
        await this.initDistributionRules(this.target);
      }
      this.overlayShow = true;
      if (isNoEmptyArr(this.list)) {
        setTimeout(() => {
          this.overlayShow = false;
          this.popupShow = true;
        }, 500);
      } else {
        const res = this.$api.getProductDetail(this.queryForm);
        console.log(res);
        const dataList = await getSpecInfo();
        this.overlayShow = false;
        this.specList = dataList;
        this.popupShow = true;
      }
    },
    /**
     * 配送方式选择
     */
    handleOpenSendTypeClick() {
      if (!this.deliveryRules) return;
      //&& this.deliveryRules.length > 1
      if (Array.isArray(this.deliveryRules)) {
        this.sendTypeShow = true;
      }
    },
    /**
     * 初始话规格
     */
    initAddressInfo(currentAddress, addressList) {
      // 如果存在已经选择的地址信息，则不做默认选择
      if (currentAddress && currentAddress.addressId) {
        this.setAddresInfo(currentAddress);
      } else if (Array.isArray(addressList) && addressList.length > 0) {
        this.setAddresInfo(addressList[0]);
      }
    },

    handleSelectShopChange(record) {
      if (record) {
        this.shopInfo = {
          address: record.address,
          shopDetail: record.address,
          shopId: record.shop_id,
          shopName: record.shop_name
        };
      }
      this.shopAddressShow = false;
    },
    handleOverlimit(val) {
      console.log('handleOverlimit', this.payCount);
      if (this.payCount <= 1) {
        if (val == 'plus') {
          Toast("购买数量最多1件");
        } else
          Toast("购买数量最少1件");
        return
      } else if (this.payCount >= this.selectedSpec.limitNum) {
        Toast(`最大购买数量为${this.payCount}件`);
        return
      }
    },
    onChange(val) {
      this.handleSeleceAddressItem({ addressInfo: this.addressInfo, target: this.target })
    },
    /**
     * 添加购物车
     */
    handleAddMyCartClick() {
      // 加购 不验证地址
      // if (Number(this.addressInfo.addressId) === 0) { // 不可配送
      //   this.handleAddressOpen(1)
      //   return;
      // }
      // if (Number(this.isDistribution) === 0) { // 不可配送
      //   Toast(`当前地址不支持配送`);
      //   return;
      // }
      if (Number(this.selectedSpec.status) === 3) {
        Toast('补货中！请重新选择')
        return
      }
      if (Number(this.selectedSpec.status) === 2) {
        Toast('已下架！请重新选择')
        return
      }
      this.onSubmit("addMyCart");
      // this.showDialog(1)
    },

    showDialog(type) {
      this.$Dialog.confirm({
        title: '提示',
        message: '是否已确认配送方式和配送时间',
      })
        .then(() => {
          // on confirm
          if (type === 1) {
            this.onSubmit("addMyCart");
          } else {
            this.onSubmit("buyNow");
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    /**
     * 立即购买
     */
    handlebuyNowClick() {
      if (Number(this.addressInfo.addressId) === 0) { // 不可配送
        this.handleAddressOpen(1)
        return;
      }
      // if (Number(this.isDistribution) === 0) { // 不可配送
      //   Toast(`当前地址不支持配送`);
      //   return;
      // }
      if (Number(this.selectedSpec.status) === 3) {
        Toast('补货中！请重新选择')
        return
      }
      if (Number(this.selectedSpec.status) === 2) {
        Toast('已下架！请重新选择')
        return
      }
      this.onSubmit("buyNow");
      // this.showDialog(2)
    },
    /**
     * 打开选择收货地址
     */
    handleAddressOpen(val) {
      if (!this.distributionRulesLock) {
        this.$refs.addressPopupRef.open(val); 
      } else {
        Toast('正在获取配送城市，请稍等～');
      }
    },
    onSubmit(type) {
      if (this.sendSelectType == 1 && !this.shopInfo) {
        Toast('请选择商家地址')
        return
      }
      if (!isLogin()) return;
      this.$emit("submit", {
        type: type,
        selectedSpec: {
          ...this.selectedSpec,
          count: this.payCount // 购买数量
        }, // 当前选择的规格
        deliveryData: {
          addressInfo: this.addressInfo, // 配送地址
          shopInfo: this.shopInfo,
          deliveryRules: this.deliveryRules, // 配送规则
          deliveryInfo: this.deliveryInfo, // 配送信息
          sendSelectType: this.sendSelectType, // 配送类型
          leaveWordText: this.leaveWordText, // 留言信息
          notesText: this.notesText, // 备注信息
          showAddressBar: this.showAddressBar, // 是否显示结算页面的上方地址栏. 快递商品可以显示,蛋糕类不支持快递的不显示
        }
      });
      this.popupShow = false;
    },
    setAddresInfo(record, target) {
      if (target) {
        this.addressInfo = { ...record, dsId: target.dsId, dsUserId: target.dsUserId };
      }
      this.addressInfo = { ...record };

    },

    /**
     * 选择收货地址 change 回调
     */
    async handleSeleceAddressItem({ addressInfo, target }) {
      this.target = target
      this.setAddresInfo(addressInfo, target); // 设施当前收货地址
      if (!this.addressInfo.addressId) {
        this.noneAddressFlag = true;
        this.leaveWordPlaceholder = '请添加地址后再填写祝福语';
        this.notesPlaceholder = '请添加地址后再填写备注';
      } else {
        this.noneAddressFlag = false;
        this.leaveWordPlaceholder = '请输入祝福语，最多20个字';
        this.notesPlaceholder = '请输入需要备注的内容';
      }
      // 有无地址,均获取配送规则
      // if (this.target) {
        await this.initDistributionRules(this.target);
      // }
    },
    /** 处理配送规则 */
    async initDistributionRules(target) {
      this.distributionRulesLock = true;
      const distributionRules = await queryDistributionRules(
          {
            cityCode: this.queryForm.cityCode,
            quantitys: this.payCount,
            distributionRuleId: this.currentDistribution,
            specId: this.selectedSpec.id,
            addrId: this.buyNowShow?target?.dsId:''// 立即购买需要传输地址信息,加购不处理地址信息
          },
          this.queryForm.brandId
        );
        if (distributionRules) {
          this.cantBuy = false// 不能买和加入购物车
          this.textCar = '加入购物车'
          this.textBuy = '立即购买'
          this.isDistribution = distributionRules.isDistribution
          this.deliveryRules = distributionRules.deliveryRules;
          this.deliveryInfo = distributionRules.deliveryInfo;
          this.sendSelectType = distributionRules.sendSelectType;
          this.shopInfo = distributionRules.shopInfo;
          this.validate_delivery_dates = distributionRules.validate_delivery_dates;
          if (this.sendSelectType === 1 && !this.noneAddressFlag) {
            // 当前选择的是 门店自提
            const shopList = await getShopListInfo(this.addressInfo.addressId, this.queryForm.brandId);
            this.shopList = shopList;
          }
          this.addCartShow = this.addCartShow ? distributionRules.showAddcard : false;
          // 1是 0否 显示结算页面的上方地址栏. 快递商品可以显示,蛋糕类不支持快递的不显示, 直充商品无需配送不显示.
          this.showAddressBar = distributionRules.showAddcard ? 1 : 0;
          this.noneSupportFlag = false;
          this.$emit("addressChange", { addressInfo: target, distributionRules });
        } else {

          this.isDistribution = null
          this.deliveryRules = [];
          this.deliveryInfo = [];
          this.sendSelectType = 0;
          this.shopInfo = [];
          this.validate_delivery_dates = [];
          this.cantBuy = true// 不能买和加入购物车
          this.textCar = '不支持配送'
          this.textBuy = '不支持配送'
          this.noneSupportFlag = true;
          // this.$emit("addressChange", { addressInfo: target, distributionRules });
          // this.$emit("cantBuy");
        }
        // 释放配送规则锁,允许再次获取规则
        this.distributionRulesLock = false;
    },
    handleSpecChange(record) {
      this.selectedSpec = record || {};
      console.log('  this.handleSpecChange   ',this.specDataLoad);
      if (!this.specDataLoad) {
        // loadAfter方法执行时判断
        this.specDataLoad = true;
      } else {
        this.onceLoad();
      }
      this.payCount = 1;
      this.$emit("specChange", { selectedSpec: this.selectedSpec, count: this.payCount });
    },
    handleTicketChange(record) {
      this.selectedSpec = record || {};
      console.log('  this.handleTicketChange   ',this.specDataLoad);
      if (!this.specDataLoad) {
        // loadAfter方法执行时判断
        this.specDataLoad = true;
      } else {
        this.onceLoad();
      }
      this.payCount = 1;
      this.$emit("ticketChange", { selectedSpec: record, count: this.payCount });
    },

    handleClose() {
      this.popupShow = false;
    },
    onceLoad() {
      this.overlayShow = true;
      setTimeout(() => {
        this.overlayShow = false;
      }, 400);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
