<template>
  <div>
    <!-- <OverlayLoad :show="loading"></OverlayLoad> -->
    <div class="good height-100">
      <!-- 轮播图 -->
      <van-swipe class="my-swipe img" :autoplay="10000" :style="{ height: bannerHeight + 'px' }"
        indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index">
          <van-image :src="item" alt="" class="img" :style="{ height: bannerHeight + 'px' }" fit="cover" />
        </van-swipe-item>
      </van-swipe>
      <div class="card">
        <div class="p-tb-10">
          <span class="goods-price-item"><span>{{ selectPrice }}</span> 点</span>
        </div>
        <div style="font-weight: 600; font-size: 1.05rem; margin-top: 0.6rem">
          <span class="titletag" v-if="getTitleTag()" :style="{ background: getTitleTag().colorCode }">{{
        getTitleTag().name }}</span>
          {{ detail?.productName }} <span v-if="chousetype"> {{ chousetype }}</span>
        </div>
        <div style="margin: 0.8rem 0; font-size: 0.8rem; color: #666">{{ detail?.description }}</div>
        <div class="tags">
          <span class="tag" v-for="(val, index) in getTagList()" :key="index">{{ val.name }}</span>
        </div>
      </div>
      <div class="card">
        <div class="p-5 jcsb m-tb-10">
          <DetailsSpecItem label="品牌" :text="detail?.brandName" @click="handleProductList"></DetailsSpecItem>
          <DetailsSpecItem label="选择" :text="selectGoods" @click="specPopupOpen"></DetailsSpecItem>
          <!-- 
          <DetailsSpecItem v-if="sendTypeStr" label="配送方式" :text="sendTypeStr" @click="specPopupOpen"></DetailsSpecItem>
          <DetailsSpecItem v-if="address" label="配送地址" :text="address" @click="specPopupOpen"></DetailsSpecItem>
          <DetailsSpecItem v-if="sendSelectType == 1" label="商家地址" :text="shopDetail" @click="SelectShopAddress">
          </DetailsSpecItem>
          -->
        </div>
      </div>
      <!-- <div class="card">
        <span style="font-size: 0.9rem; color: #666"> {{ detail?.product[0]?.extraData.distrubtion }}</span>
      </div> -->
      <div class="card">
        <div class="context">
          <div class="icon"></div>
          商品详情
        </div>
        <van-image style="width: 100%" v-for="(item, index) in goodsDetailImgList" :key="index" :src="item">
        </van-image>
      </div>
      <van-image style="width: 100%" v-if="detail?.noticeImage" :src="detail?.noticeImage">
      </van-image>
      <van-goods-action>
        <van-goods-action-icon icon="cart-o" text="购物车" @click="goCar" />
        <!-- <van-goods-action-icon icon="shop-o" text="店铺" /> -->
        <van-goods-action-button type="warning" :disabled="cantBuy" :text="textCar"
          v-if="showAddCard && productType !== '0'" @click="() => specPopupOpen('addCart')" />
        <van-goods-action-button type="danger" :disabled="cantBuy" :text="textBuy"
          @click="() => specPopupOpen('buyNow')" />
      </van-goods-action>
    </div>
    <SpecPopup v-if="productDetaiLoad" :goodsType="detail.category1Id && detail.category1Id == '5' ? 'cake' : 'normal'"
      ref="specPopupRef" :list="specsLsit" :current-address="currentAddressInfo" :product-type="productType"
      :venue-list="detail?.venueList" :current-distribution="detail.distributionRuleId" :query-form="detailform"
      :channel-type="2" @addressChange="handleSpecPopupAddressChange" @specChange="handleSpecChange"
      @ticketChange="handleTicketChange" @submit="handleSpecSubmit"></SpecPopup>
  </div>
</template>

<script>
// import { Dialog, Toast } from "vant";
import DetailsSpecItem from "./DetailsSpecItem.vue";
import DetaillNow from "../../components/goods/DetaillNow.vue";
import SelectAddress from "../../components/goods/SelectAddress.vue";
import ShopAddress from "../../components/goods/ShopAddress.vue";
import SendType from "../../components/goods/SendType.vue";
import SpecPopup from "../../components/SpecPopup/index.vue";
import OverlayLoad from "../../components/SpecPopup/OverlayLoad.vue";
import { goMyCart, getGoodsDetail, submitBuyGoodsInfo, addCakeAddress, queryDistributionRules } from "./index.action";
import Utils from "../../utils";
// import { sendSelectTypeOptions } from "../../config/options";
export default {
  name: "Details",
  props: {},
  data() {
    return {
      bannerList: [], // banner图
      goodsDetailImgList: [], // 商品详情数组
      specsLsit: [], // 可选规格列表
      loading: false,
      productDetaiLoad: false, // 详情信息,是否加载完成
      detail: null,
      bannerHeight: 0,
      //商品规格
      show: false,
      selectGoods: "", // 展示所选规格的商品
      sendTypeStr: "", // 展示的配送信息
      // sendTypeAddress:'',
      address: "",
      detailform: {
        cardId: "",
        cityCode: "",
        brandId: "",
        productId: ""
      },
      cantBuy: true,// 不能买和加入购物车
      textCar: '加入购物车',
      textBuy: '立即购买',

      currentAddressInfo: {},
      ShopDetail: null,
      userId: "",
      chousetype: "",
      selectPrice: '',
      selectMarketprice: "",
      goodNum: 1, // 商品数量
      specId: this.$route.query.specId, // 规格 id
      showShopAddress: false,
      // sendSelectType: 0, //1 自提  2 快递 3 商户
      dsUserId: "",
      dsId: "",
      shipDate: "",
      shipTimeText: "",
      shopDetail: "",
      shopId: "",
      shopName: "",
      consigneeAddress: "",
      consigneeName: "",
      consigneePhone: "",
      addressId: "",
      productType: "", //0 票 1 蛋糕 2 其他
      delivery_amount: "0",
      addressList: [], // 地址信息list
      addressInfo: {
        address: "",
        consigneeAddress: "",
        consigneeName: "",
        consigneePhone: "",
        addressId: "",
      }, // 收货地址信息
      showAddCard: true,
    };
  },
  components: { DetailsSpecItem, DetaillNow, SelectAddress, SendType, ShopAddress, SpecPopup, OverlayLoad },

  mounted() {
    this.init();
    this.bannerHeight = (document.body.clientWidth || window.innerWidth || document.documentElement.clientWidth)
    var timer = setInterval(() => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0
      clearInterval(timer);
    }, 200)
    // if (window.history && window.history.pushState) {
    //   // 向历史记录中插入了当前页
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener('popstate', this.goBack, false);
    // }
  },

  // destroyed() {
  //   window.removeEventListener('popstate', this.goBack, false);
  // },
  computed: {
    // 购物车的角标
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getTitleTag() {
      let Tag = undefined
      if (this.detail?.labelList) {
        this.detail?.labelList.forEach(element => {
          if (element.smallLabelType == '1') {
            Tag = element
          }
        });
      }
      return Tag
    },
    getTagList() {
      let Tag = []
      if (this.detail?.labelList) {
        this.detail?.labelList.forEach(element => {
          if (element.smallLabelType !== '1') {
            Tag.push(element)
          }
        });
      }
      return Tag
    },
    formNumber(val) {
      if (val) {
        return parseFloat(val).toFixed(2)
      } else return ''

    },
    handleProductList() {
      if (this.detail && this.detail.id) {
        const { category1Id, category2Id, brandName } = this.detail;
        const timeStamp=new Date().getTime()
        this.$router.push({
          // path: "/ProductList",
           path:`/ProductList/${timeStamp}/${brandName}`,
          // query: {
          //   brandName: brandName,
          //   category1Id: category1Id,
          //   category2Id: category2Id,
           
          // },
          // params: { time: 123 },
          // reflesh: true
        });
      // this.$router.push(path);
      }


    },
    init() {
      this.userId = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : "";
      this.detailform.cardId = localStorage.getItem("cardId");
      this.detailform.cityCode = localStorage.getItem("cityCode");
      this.detailform.productId = this.$route.query.id;
      this.detailform.brandId = this.$route.query.brandId;
      this.getProductDetail();

    },

    // 获取地址列表数据
    async getAddress() {
      // 不获取地址,直接获取配送规则,只为显示 配送方式
      // const res = await this.$api.getAddress(this.userId);
      // if (res.code == 0) {
      //   //有地址则处理地址相关,无地址获取规则
      //   if (Array.isArray(res.data) && res.data.length > 0) {
      //     this.addressList = res.data;
      //     const defaultAddress = res.data.find((item) => Number(item.defaultFlag) === 1) || res.data[0];
      //     const addressItemRes = await this.setAddresInfo(defaultAddress);
      //     this.handleSeleceAddressItem({ addressInfo: this.addressInfo, target: addressItemRes })
      //   } else {
          const addressItemRes = await this.setAddresInfo();
          this.$emit("loadAfter", { addressInfo: this.addressInfo, target: addressItemRes });
          this.handleSeleceAddressItem({ addressInfo: this.addressInfo, target: addressItemRes });
      //   }
      // }
    },
    /**
     * 选择收货地址 change 回调
     */
    async handleSeleceAddressItem({ addressInfo, target }) {
      this.target = target
      // 无收货地址,依然获取配送规则
      // if (this.target) {
        const distributionRules = await queryDistributionRules(
          {
            cityCode: this.detailform.cityCode,
            quantitys: 1,
            distributionRuleId: this.detail.distributionRuleId,
            specId: this.detail.specificationList[0].id
            // addrId: target?.dsId
          },
          this.detailform.brandId
        );
        if (distributionRules) {
          this.cantBuy = false// 不能买和加入购物车
          this.textCar = '加入购物车'
          this.textBuy = '立即购买'
          this.isDistribution = distributionRules.isDistribution
          this.deliveryRules = distributionRules.deliveryRules;
          this.deliveryInfo = distributionRules.deliveryInfo;
          // this.sendSelectType = distributionRules.sendSelectType;
          this.shopInfo = distributionRules.shopInfo;
          this.validate_delivery_dates = distributionRules.validate_delivery_dates;
          // if (this.sendSelectType === 1) {
          //   // 当前选择的是 门店自提
          //   const shopList = await getShopListInfo(this.addressInfo.addressId, this.detailform.brandId);
          //   this.shopList = shopList;
          // }
          this.handleSpecPopupAddressChange({ addressInfo: target, distributionRules })
        } else {
          this.isDistribution = null
          this.deliveryRules = [];
          this.deliveryInfo = [];
          // this.sendSelectType = 0;
          this.shopInfo = [];
          this.validate_delivery_dates = [];
          this.cantBuy = true// 不能买和加入购物车
          this.textCar = '不支持配送'
          this.textBuy = '不支持配送'
        // }
      }
    },

    /**
     * 设置选择城市配置信息，
     * 更新城市列表的dsUserId 以及dsId值
     */
    async setAddresInfo(record) {
      if (record) {
        const { id, addressCname, addressQname, addressPname, address, recipient, recipientMobile, houseNum } = record;
        this.addressInfo.address = addressCname + addressQname + addressPname + address + houseNum;
        // this.address = addressCname + addressQname + addressPname + address + houseNum;
        this.addressInfo.consigneeAddress = addressPname + address + houseNum;
        this.addressInfo.consigneeName = recipient;
        this.addressInfo.consigneePhone = recipientMobile;
        this.addressInfo.addressId = id;
        const addCakeAddressRes = await addCakeAddress(record);
        this.addressList.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              dsUserId: addCakeAddressRes.dsUserId,
              dsId: addCakeAddressRes.dsId
            };
          }
          return item;
        });
        let addressinfo = this.addressList.find((item) => item.id === id)
        addressinfo.dsUserId = addCakeAddressRes.dsUserId
        addressinfo.dsId = addCakeAddressRes.dsId
        return addressinfo;
      } else {
        this.addressInfo.address = "暂无收货地址点击添加地址";
      }
    },
    // 获取商品详情
    async getProductDetail() {
      this.loading = true;
      const goodsData = await getGoodsDetail(this.detailform, this.specId);
      this.productDetaiLoad = true;
      if (goodsData) {
        this.detail = goodsData.target;
        this.productType = this.detail.productType
        if (this.detail.venueList && this.detail.venueList.length > 0) {
          this.detail.venueList[0].checked = true
        }
        this.specsLsit = goodsData.specsArr;
        this.setGoodsInfo(goodsData.defaultSpecs);
        this.getAddress()
      } else {
        var timer = setInterval(() => {
          this.$router.back();
          clearInterval(timer);
        }, 1000)

      }
      this.loading = false;
    },
    SelectShopAddress() {
      if (this.ShopDetail) {
        this.showShopAddress = true;
      }
    },
    // 去购物车
    goCar() {
      goMyCart(2);
    },
    /**
     * 打开规格选择内容页
     */
    specPopupOpen(params) {
      if (!this.showAddCard) {
        params = 'buyNow'
      }
      this.$refs.specPopupRef.open(params);
    },
    /**
     * 规格菜单点击加入【购物车|立即购买】执行函数
     */
    async handleSpecSubmit({ type, selectedSpec, deliveryData }) {
      this.setGoodsInfo(selectedSpec, selectedSpec.count);
      // if (!this.address) {
      //   addressTag();
      //   return;
      // }
      this.loading = true;
      await submitBuyGoodsInfo(
        type,
        { userId: this.userId, ...this.detailform },
        deliveryData,
        selectedSpec,
        this.detail
      );
      this.loading = false;
    },

    /**
     * 规格选择后触发方法
     */
    handleSpecChange({ selectedSpec, count }) {
      this.setGoodsInfo(selectedSpec, count);
    },

    /**
     * 选择演出票
     * @param {} param0 
     */
    handleTicketChange({ selectedSpec, count }) {
      this.goodNum = count; // 购买数量
      this.specId = selectedSpec.skuId || "";
    },
    /**
     * 配送地址变更执行函数
     */
    handleSpecPopupAddressChange({ addressInfo, distributionRules }) {
      this.cantBuy = false// 不能买和加入购物车
      this.textCar = '加入购物车'
      this.textBuy = '立即购买'
      // this.setAddressItem(addressInfo)
      this.setDeliveryRulesInfo(distributionRules)
    },
    setDeliveryRulesInfo(distributionRules) {
      if (!distributionRules) return;
      // const { sendSelectType, shopInfo, deliveryRules, deliveryInfo } = distributionRules;
      const { shopInfo, deliveryRules, deliveryInfo } = distributionRules;
      if (shopInfo) {
        this.shopId = shopInfo.shopId;
        this.shopName = shopInfo.shopName;
        this.shopDetail = shopInfo.shopDetail;
      }
      this.showAddCard = distributionRules.showAddcard
      this.sendTypeStr = deliveryInfo.sendTypeStr;
      this.shipDate = deliveryInfo.shipDate;
      this.shipTimeText = deliveryInfo.shipTimeText;
      this.delivery_amount = deliveryInfo.deliveryAmount;
      // this.sendSelectType = sendSelectType;
    },
    /**
     * 地址相关信息回填
     */
    setAddressItem(item) {

      this.currentAddressInfo = item;
      this.address = item.addressCname + item.addressQname + item.addressPname + item.address + item.houseNum;
      this.consigneeAddress = item.addressPname + item.address + item.houseNum;
      this.consigneeName = item.recipient;
      this.consigneePhone = item.recipientMobile;
      this.addressId = item.id;

      this.dsId = item.dsId;
      this.dsUserId = item.dsUserId;
    },
    /**
     * 商品相关的data数据回填
     */
    setGoodsInfo(specInfo, count = 1) {
      const specInfo_ = specInfo || {};
      const { id, specifications, showPrice, ticketPrice, bannerList, coverImage, htmEditorList } = specInfo_;
      this.goodNum = count; // 购买数量
      this.specId = id || "";
      if (this.productType === '0') {
        this.selectGoods = ticketPrice ? `${ticketPrice}` : "暂无商品";
        this.chousetype = ticketPrice || "暂无商品";
      } else {
        this.selectGoods = specifications ? `${specifications}` : "暂无商品";
        this.chousetype = specifications || "暂无商品";
      }

      this.selectPrice = showPrice || "暂无价格";
      this.setBannerList(coverImage, bannerList, htmEditorList);
    },
    /**
     * 将规格图片推到banner图的主位上
     */
    setBannerList(specsImg, bannerList, htmEditorList) {
      // if (!specsImg) return;
      this.bannerList = Utils.isNoEmptyArr(bannerList) ? [specsImg, ...bannerList] : [specsImg];
      this.goodsDetailImgList = htmEditorList
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log('  beforeRouteEnter   detail');
  //   next();

  // },
  // beforeRouteLeave(to, from, next) {

  //   console.log('  beforeRouteLeave  detail ',to.meta.refresh);
  //   next();
  // },


};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
